import ContactForm from '../components/ContactSection/ContactForm';
import React from 'react';
import { pageTitle } from '../helper';
import Div from '../components/Div';
import PageHeading from '../components/PageHeading';
import Modal from 'react-bootstrap/Modal';
import SectionHeading from '../components/SectionHeading';
import Spacing from '../components/Spacing';
import ContactInfoWidget from '../components/Widget/ContactInfoWidget';
import clientApi from '../apiServices/clientApi'
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import ScrollToTop from "react-scroll-to-top";
import Validation from '../components/Validation';

export default function ContactPage() {
  const [smShow, setSmShow] = useState(false);
  const [errors,setErrors]=useState({})
  const [contact, setContact] = useState({
    firstName:"",
    lastName:"",
    businessEmail:"",
    firstPhone:"",
    message:"",
    type:"registered"
  });

  const handleChange=(e)=>{
    const value=e.target.value;
    setContact({
      ...contact,
      [e.target.name]:value
    })
  }
  const handleSubmitForm=(e)=>{
    e.preventDefault();
 
    const userData={
      lastName:contact.lastName,
      firstName:contact.firstName,
      businessEmail:contact.businessEmail,
      firstPhone:contact.firstPhone,
      type:contact.type,
      message:contact.message

    }
   
    setErrors(Validation({userData}))

    clientApi.contact(userData)
    .then((response) => {

      console.log(response.data)
      setSmShow(true)

    })
    .catch(error => {
    
      console.error(error);
    });
  }

  return (
    <>
        <Helmet >
      <title>{ pageTitle('Contact Us')}</title>
      <meta property="og:title" content="Contact Us" />
    <meta
    property="og:description"
    content="Contact Us by send message with firstname,lastname and email" />
    </Helmet>
      <PageHeading
        title="Contact Us"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Contact"
      />
      <Spacing lg="100" md="80" />
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(true)}
        aria-labelledby="example-modal-sizes-title-sm"
        animation="true"
      >
        <Modal.Header closeButton className='modal-btn'>
          <Modal.Title id="example-modal-sizes-title-sm" className='text-light text-center'style={{fontSize:"16px"}} >
            Your Message sent successfully
          </Modal.Title>
        </Modal.Header>
      </Modal>
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Do you have a project <br/>in mind?"
              subtitle="Getting In Touch"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            
          <ContactForm
              handleSubmitForm={handleSubmitForm}
              handleChange={handleChange}
              contact={contact}
              errors={errors}
            />
        
          </Div>
        </Div>
      </Div>

      <ScrollToTop smooth  className='scrolltotop' color='#b7346c' width="20" height="20" viewBox="0 0 20 20"  xmlns="http://www.w3.org/2000/svg" svgPath= "M0 10L1.7625 11.7625L8.75 4.7875V20H11.25V4.7875L18.225 11.775L20 10L10 0L0 10Z"/>
    </>
  );
}
