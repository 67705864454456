
import axiosApi from '../api'

export default {
    async getProjects(params) {
      return await axiosApi
        .get(`projects`, { params: params })
        .then(res => {
          console.log(res.data + "this is data ")

          return res.data
        })
        .catch(e => {
          console.log(
            JSON.stringify(e.response?.data?.errors) + "this is an error ",
          )

          return e.response?.data?.errors
        })
    },

    async getProject(id) {
      return await axiosApi
        .get(`projects/${id}`)
        .then(res => {
          console.log(res.data + "this is data ")

          return res.data
        })
        .catch(e => {
          console.log(
            JSON.stringify(e.response?.data?.errors) + "this is an error ",
          )

          return e.response?.data?.errors
        })
    },
}
