import React, { useEffect } from 'react'
import { pageTitle } from '../helper'
import Cta from '../components/Cta'
import PageHeading from '../components/PageHeading'
import Div from '../components/Div'
import Spacing from '../components/Spacing'
import { useState } from 'react'
import SectionHeading from '../components/SectionHeading'
import ScrollToTop from "react-scroll-to-top";
import AnimatedCircularProgress from '../components/CircularProgress';
import eventsApi from '../apiServices/eventsApi'
import { Helmet } from 'react-helmet'
import CardBlog from '../components/BlogCard'
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const fetchEvents = async ({ queryKey }) => {
  const [_key, options] = queryKey;
  const response = await eventsApi.getEvents(options);
  return response.data.slice().reverse();
};

function EventComponent() {
  const options = {
    page: 1,
    limit: 1000,
  };

  const [progress, setProgress] = useState(20);

  const { data: events = [], error, isLoading } = useQuery({
    queryKey: ['events', options],
    queryFn: fetchEvents,
  });

  const displayEvents = events.map((item, index) => (
    <Div key={index} className="col-12 col-md-6 col col-lg-3 col-sm-12">
      <CardBlog
        _id={item._id}
        imageCover={item.imageCover}
        name={item.name}
      />
      {events.length > index + 1 && <Spacing lg='95' md='60' />}
    </Div>
  ));

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 40));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitle('Events')}</title>
        <meta property="og:title" content="Events" />
        <meta
          name="description"
          content="Events and their details"
        />
      </Helmet>
      <PageHeading
        title='Our Events'
        bgSrc='/images/blog_hero_bg.jpeg'
        pageLinkText='Events'
      />
      <Spacing lg='30' md='30' />
      <Div className='cs-shape_wrap_4'>
        <Div className="container">
          <Div className="row">
            <Div className="col-12">
              <SectionHeading
                title='Events'
                subtitle=''
                variant="cs-style1 text-center"
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className='col-12'>
              {events.length > 0 ?
                <Div className='row'>
                  {displayEvents}
                  <Spacing lg='0' md='30' />
                </Div>
              :
                <Div className='container' style={{ width: 200, height: 200 }}>
                  <AnimatedCircularProgress percentage={progress} />
                </Div>}
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      <Div className="container">
        <Cta
          title="Let's discuss making  <br />something <i>cool</i>"
          btnText="Contact Us"
          btnLink="/contact"
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <ScrollToTop
        smooth
        className='scrolltotop'
        color='#b7346c'
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        svgPath="M0 10L1.7625 11.7625L8.75 4.7875V20H11.25V4.7875L18.225 11.775L20 10L10 0L0 10Z"
      />
    </>
  );
}

export default function EventPage() {
  return (
    <QueryClientProvider client={queryClient}>
      <EventComponent />
    </QueryClientProvider>
  );
}
