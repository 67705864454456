import { Icon } from '@iconify/react';
import React from 'react';
import Slider from 'react-slick';
import Div from '../Div';
import Timeline from '../Timeline';

export default function VisionlineSlider() {
  const timelineData = [
    [
      {
        year: 'Vision',
        position: `Our vision is rooted
        in the belief that every client deserves a tailored and personalized approach to marketing.
        We envision ourselves as the pioneers in bridging the gap between brands and consumers through innovative marketing solutions that leave a lasting mpact.
        360 marketing solutions that leave a lasting impact.  `

      },

    ],
    [
        {
            year: 'Mission',
            position: 'We leverage our robust marketing team and advanced tools to transform your ideas into tangible visions. With our expertise, we ensure that your mere representation of thoughts is brought to life. Whether you own a business or are starting one, we are here to provide close support and guidance every step of the way. '
          },

      ],


  ];

  /** Slider Settings **/
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    arrows: false,
    responsive: [
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="cs-arrow_style3">
      {timelineData.map((item, index) => (
        <Div key={index}>
          <Timeline columnData={item} className="text-justify"/>
        </Div>
      ))}
    </Slider>
  );
}
