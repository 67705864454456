import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react';
import Div from '../Div';

export default function SocialWidget() {
  return (
    <Div className="cs-social_btns cs-style1">
      <Link to='https://www.linkedin.com/company/pbcegy/' target='_blank' className="cs-center">
        <Icon icon="fa6-brands:linkedin-in" />
      </Link>
      <Link to='https://www.facebook.com/pbcegy/' target='_blank' className="cs-center">
        <Icon icon="fa6-brands:facebook" />
      </Link>
      <Link to='https://www.instagram.com/pbcegy/' target='_blank' className="cs-center">
        <Icon icon="fa6-brands:instagram" />
      </Link>
      <Link to='https://api.whatsapp.com/send?phone=201118816000' target='_blank' className="cs-center">
        <Icon icon="fa6-brands:whatsapp" />
      </Link>

    </Div>
  )
}
