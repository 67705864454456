
import projectsApi from '../apiServices/projectsApi';
import serviceApi from '../apiServices/servicesApi'
import { Helmet } from 'react-helmet';
import AnimatedCircularProgress from '../components/CircularProgress';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { pageTitle } from '../helper';
import Cta from '../components/Cta';
import PageHeading from '../components/PageHeading';
import Portfolio from '../components/Portfolio';
import Div from '../components/Div';
import SectionHeading from '../components/SectionHeading';
import Spacing from '../components/Spacing';
import ScrollToTop from "react-scroll-to-top";
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';


const queryClient = new QueryClient();

const fetchServices = async (options) => {
  const response = await serviceApi.getServices(options);
  return response.data.slice().reverse();
};

const fetchProjects = async (options) => {
  const response = await projectsApi.getProjects(options);
  return response.data;
};

function PortfolioComponent() {
  const options = {
    sort: "-createdAt",
    page: 1,
    limit: 50,
  };

  const { data: services = [], error: servicesError, isLoading: servicesLoading } = useQuery({
    queryKey: ['services', options],
    queryFn: () => fetchServices(options),
  });

  const { data: projects = [], error: projectsError, isLoading: projectsLoading } = useQuery({
    queryKey: ['projects', options],
    queryFn: () => fetchProjects(options),
  });

  const [progress, setProgress] = useState(20);
  const [active, setActive] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 40));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitle('Portfolio')}</title>
        <meta property="og:title" content="Portfolio" />
        <meta name="description" content="Portfolio and their details" />
      </Helmet>

      <PageHeading
        title="Portfolio"
        bgSrc="images/portfolio_hero_bg.jpeg"
        pageLinkText="Portfolio"
      />
      <Spacing lg="30" md="30" />
      <Div className="container">
        <SectionHeading title="Our Portfolio" subtitle="" className="text-center" />
        <Div className="cs-portfolio_1_heading">
          {services.length > 0 ? (
            <Div className="cs-filter_menu cs-style1 text-center mt-4">
              <ul className="cs-mp0 cs-center">
                {services
                  .filter((item) => item.title !== 'Lead Generation' && item.title !== 'Radio Ads' && item.title !== 'Retail Activations')
                  .map((item, index) => (
                    <li
                      className={active === item._id ? 'active' : ''}
                      key={index}
                      onClick={() => setActive(item._id)}
                    >
                      <span>{item.title}</span>
                    </li>
                  ))}
              </ul>
            </Div>
          ) : (
            <Div className='mt-3' style={{ width: 200, height: 200 }}>
              <AnimatedCircularProgress percentage={progress} />
            </Div>
          )}
        </Div>
        <Div className="row">
          {projects.map((item, index) => (
            active === item.service && (
              <Div className="col-lg-12 col-md-12" key={index}>
                <Portfolio
                  _id={item._id}
                  imageCover={item.imageCover}
                  title={item.title}
                  description="Read more"
                  date={item.date}
                  variant="cs-style1 cs-type1"
                />
                {item.videoLink && (
                  <Div className="text-center mt-5 mb-5">
                    <iframe title='video' className='video' src={item.videoLink} frameBorder="0" allow="accelerometer; autoplay" allowFullScreen></iframe>
                  </Div>
                )}
              </Div>
            )
          ))}
        </Div>
      </Div>
      <Spacing lg='130' md='80'/>
      <Cta
        title="Let's discuss making <br />something <i>cool</i>"
        btnText="Contact Us"
        btnLink="/contact"
        bgSrc="/images/cta_bg_3.jpeg"
      />
      <ScrollToTop smooth className='scrolltotop' color='#b7346c' width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" svgPath="M0 10L1.7625 11.7625L8.75 4.7875V20H11.25V4.7875L18.225 11.775L20 10L10 0L0 10Z"/>
    </>
  );
}

export default function PortfolioPage() {
  return (
    <QueryClientProvider client={queryClient}>
      <PortfolioComponent />
    </QueryClientProvider>
  );
}
