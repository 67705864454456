import axios from "axios"


const axiosApi = axios.create({
  baseURL: `https://pbc-back.onrender.com/api/v1/`,
  headers: {
    "Content-Type": "application/json",
  },
})


/*
 * The interceptor here ensures that we check for the token in local storage every time an axiosApi request is made
 */
axiosApi.interceptors.request.use(
  config => {


    let token = null

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`
    }

    return config
  },

  error => {
    return Promise.reject(error)
  },
)
axiosApi.interceptors.response.use(response => {


  return response
})

export default axiosApi
