import React from 'react'
import { Link } from 'react-router-dom'
import Div from '../Div'
import './blogCard.scss'

export default function CardBlog({imageCover, name,_id,eventSections}) {
  return (
    <Link  className="cs-card cs-style1 d-flex" to={`/events/${_id}`} >
      <>
        <img src={imageCover} alt={name} />

        <Div className="cs-card_info_service">
  
          <h2 className="cs-card_title_card">{eventSections}</h2>
        </Div>
      </>
    </Link>
  )
}
