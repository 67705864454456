import PostSlider from '../Slider/PostSlider';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';

export default function ActivationSection(){
    return(
        <>
      <Div className="cs-shape_wrap_4">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4 event-section">
              <SectionHeading
                title="Explore Our Activations"
                subtitle=""
                btnText="View All Activations"
                btnLink="/events"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <Div className="cs-half_of_full_width">
                <PostSlider />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
        
        </>
    )
}