import React from 'react'
import Slider from "react-slick";
import Div from '../Div';
import AnimatedCircularProgress from '../CircularProgress/';
import Post from '../Post';
import eventsApi from '../../apiServices/eventsApi';
import { useState,useEffect } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const fetchEvents = async ({ queryKey }) => {
  const [_key, options] = queryKey;
  const response = await eventsApi.getEvents(options);
  return response.data.slice().reverse();
};

function PostSlider() {
  const options = {
    page: 1,
    limit: 150,
  };

  const { data: events = [], error, isLoading } = useQuery({
    queryKey: ['events', options],
    queryFn: fetchEvents,
  });

  const [progress, setProgress] = useState(20);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 40));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  /** Slider Settings **/
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    scrollbars: true,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // if (isLoading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      {events.length > 0 ? (
        <Slider {...settings} className="cs-gap-24 slider-events">
          {events.map((item, index) => (
            <Div key={index}>
              <Post
                _id={item._id}
                imageCover={item.imageCover}
                title={item.title}
              />
            </Div>
          ))}
        </Slider>
      ) : (
        <Div className="container" style={{ width: 200, height: 200 }}>
          <AnimatedCircularProgress percentage={progress} />
        </Div>
      )}
    </>
  );
}

export default function PostSliderComponent() {
  return (
    <QueryClientProvider client={queryClient}>
      <PostSlider />
    </QueryClientProvider>
  );
}
