import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
export default function AboutCompany() {
return(
    <Div className="container">
    <Div className="row">
      <Div className="col-xl-5 col-lg-7">
        <SectionHeading
          title="Your trusted partner for business"
          subtitle="About Our Company"
        >
          <Spacing lg="16" md="20" />
          <p className="cs-m0 pt-2" style={{textAlign:"justify",hyphens: "auto"}}>
          Our distinguished team of experienced marketers and talented designers is dedicated to assisting you in taking your business to new heights and enhancing your brand's presence in the most captivating manner possible! With our comprehensive range of innovative 360 marketing solutions, we have carefully crafted packages designed to cater to your specific needs and objectives. Whether it's creating compelling advertising campaigns, designing visually stunning graphics and logos, or implementing impactful social media strategies, we have the expertise and creativity to deliver results that will drive growth and leave a lasting impression on your target audience. By leveraging our deep understanding of market trends and consumer behavior, we can help you navigate the ever-evolving digital landscape and ensure that your brand stands out from the competition.
         

          </p>
          <Spacing lg="30" md="30" />
          <Div className="cs-separator cs-accent_bg"></Div>
          <Spacing lg="25" md="40" />
        </SectionHeading>
      </Div>
      <Div className="col-lg-5 offset-xl-2">
        <img
          src="/images/2.webp"
          alt="About"
          className="w-100 cs-radius_15"

        />
        <Spacing lg="25" md="25" />
      </Div>
      <Div className="col-lg-7">
        <img
          src="/images/1.webp"
          alt="About"
          className="w-100 cs-radius_15"

        />
        <Spacing lg="25" md="25" />
      </Div>
      <Div className="col-lg-5">
        <img
          src="/images/about_img_3.webp"
          alt="About"
          className="w-100 cs-radius_15"
        />
        <Spacing lg="25" md="25" />
      </Div>
    </Div>
  </Div>
)
}