import { Icon } from '@iconify/react';
import React from 'react';
import AnimatedCircularProgress from '../CircularProgress/';
import Div from '../Div';
import Team from '../Team';
import { useState,useEffect } from 'react'
import teamApi from '../../apiServices/teamApi'
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const fetchTeam = async ({ queryKey }) => {
  const [_key, options] = queryKey;
  const response = await teamApi.getTeam(options);
  return response.data.slice().reverse();
};

function TeamSlider() {
  const options = {
    sort: "-createdAt",
    page: 1,
    limit: 100,
  };

  const { data: team = [], error, isLoading } = useQuery({
    queryKey: ['team', options],
    queryFn: fetchTeam,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /** Slider Settings **/
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  const [progress, setProgress] = useState(20);
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 40));
    }, 1000);

    return () => clearInterval(interval);
  }, []);



  return (
    <>
      {team.length > 0 ? (
        <Div className='row'>
          {team.map((item, index) => (
            <Div className='col-lg-3 col-md-6 col-sm-12 mb-5' key={index}>
              <Team
                memberImage={item.imageCover}
                memberName={item.name}
                memberDesignation={item.role}
              />
            </Div>
          ))}
        </Div>
      ) : (
        <Div className='container' style={{ width: 200, height: 200 }}>
          <AnimatedCircularProgress percentage={progress} />
        </Div>
      )}
    </>
  );
}

export default function TeamSliderComponent() {
  return (
    <QueryClientProvider client={queryClient}>
      <TeamSlider />
    </QueryClientProvider>
  );
}
