import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
export default function OurFounder() {
return(
  
    <Div className="container">
    <Div className="row">
      <Div className="col-xl-5 col-lg-6">
        <Div className="cs-image_layer cs-style1">
          <Div className="cs-image_layer_in">
            <img
              src="images/Samr.webp"
              alt="About"
              className="w-100 cs-radius_15"
            />
          </Div>
        </Div>
        <Spacing lg="10" md="40" />
      </Div>


      <Div className="col-xl-5 offset-xl-1 col-lg-6">
        <SectionHeading
          title="Samer Hosny"
          subtitle="Our Founder"
        >
          <Spacing lg="30" md="20" />
          <p className="cs-m0">

          </p>
          <Spacing lg="15" md="15" />
          <p  style={{textAlign:"justify"}}>
          As a seasoned marketer with over 20 years of experience, I am proud to have worked for some of the biggest names in the industry, including Proctor & Gamble, Nestle, and Pepsi. With my education at the prestigious University of Notre Dame, where I earned my MBA degree, I have been equipped with the knowledge and skills to excel in my field.<br/><br/>

          Throughout my career, I have honed my expertise in brand management, product development, and advertising. My deep understanding of consumer behavior has been instrumental in creating successful marketing campaigns that drove exceptional results for some of the largest household brands worldwide.<br/><br/>

          Having had the opportunity to work in Egypt, the United States, and Latin America, I have gained valuable insights into diverse markets and cultural nuances. This global experience has enriched my perspective and enabled me to develop strategies that resonate with audiences across different regions.<br/><br/>

          Today, as the proud owner of a thriving marketing agency, I collaborate with clients from a wide range of industries, helping them achieve their business goals. By leveraging digital marketing strategies, social media campaigns, and traditional advertising methods, I am committed to delivering measurable results that consistently exceed my clients' expectations.

          </p>
          <Spacing lg="30" md="30" />
          <Div className="cs-separator cs-accent_bg"></Div>
          <Spacing lg="25" md="0" />
        </SectionHeading>
      </Div>
    </Div>
  </Div>
)
}