
import axiosApi from '../api'

export default {
    async getTeam(params) {
      return await axiosApi
        .get(`team`, { params: params })
        .then(res => {
          console.log(res.data + "this is data ")

          return res.data
        })
        .catch(e => {
          console.log(
            JSON.stringify(e.response?.data?.errors) + "this is an error ",
          )

          return e.response?.data?.errors
        })
    },

    async getMember(id) {
      return await axiosApi
        .get(`team/${id}`)
        .then(res => {
          console.log(res.data + "this is data ")

          return res.data
        })
        .catch(e => {
          console.log(
            JSON.stringify(e.response?.data?.errors) + "this is an error ",
          )

          return e.response?.data?.errors
        })
    },
}
