import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const AnimatedCircularProgress = ({ percentage }) => (
  <div style={{ width: 200, height: 200 }}>
    <CircularProgressbar
      value={percentage}
    
      styles={buildStyles({
        textColor: '#fff',
        pathColor: `#6C2C78`,
        trailColor: 'transparent',  // Set trailColor to transparent for a cleaner look
      })}
    >
      {/* You can add additional animated elements inside the CircularProgressbarWithChildren */}
      <div style={{ fontSize: 16, marginTop: -5 }}>
        {/* Any additional animated elements can go here */}
      </div>
    </CircularProgressbar>
  </div>
);

export default AnimatedCircularProgress;
