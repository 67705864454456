import React, { useEffect } from 'react'
import { pageTitle } from '../helper'
import Card from '../components/Card'
import Cta from '../components/Cta'
import PageHeading from '../components/PageHeading'
import Div from '../components/Div'
import SectionHeading from '../components/SectionHeading'
import Spacing from '../components/Spacing'
import { useState } from 'react'
import serviceApi from '../apiServices/servicesApi'
import AnimatedCircularProgress from '../components/CircularProgress';
import { Helmet } from 'react-helmet'
import ScrollToTop from "react-scroll-to-top";
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
// export default function ServicesPage() {
//   const [services, setServices] = useState([]);
//   const [progress, setProgress] = useState(20);
//   const options = {
//     sort: "-createdAt",
//     page: 1,
//     limit: 200,

//   }
//   const displayServices=services.map((item, index)=> {
//     return(
//     <Div key={index} className="col-12 col-md-6 col col-lg-4 col-sm-12" >
//       <Div className="container">
//       <Card key={index}
//         _id={item._id}
//         imageCover={item.imageCover}
//         title={item.title}
//         description={item.description}
//       />
//     {services.length>index+1 && <Spacing lg='95' md='60'/>}
//     </Div>
//   </Div>

// );
//     })

//   useEffect(() => {
//     serviceApi.getServices(options)
//       .then(response => {
//         setServices(response.data.slice().reverse());
//         console.log(response.data.slice().reverse())
//       })
//       .catch(error => {
//         console.error(error);
//       });
//       const interval = setInterval(() => {
//         setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 40));
//       }, 1000);

//       return () => clearInterval(interval);
//   }, []);

//   return (
//     <>
//       <Helmet >
//       <title>{pageTitle('Services')}</title>
//       <meta property="og:title" content="Services" />
//     <meta
//         name="description"
//     content="Convention marketing is defined as brand advertising and attendee engagement outside of trade shows. This type of marketing helps companies stand out at busy trade shows or conferences. It can take on many forms ranging from mobile billboards to guerilla marketing activities." />
//     </Helmet>
//       <PageHeading
//         title='Services'
//         bgSrc='images/service_hero_bg.jpeg'
//         pageLinkText='Services'
//       />
//       <Spacing lg='30' md='30'/>

//         <Div className="container">
//           <Div className="row">
//             <Div className="col-12">
//                 <SectionHeading
//                   title='Services we can help you with'
//                   subtitle='What Can We Do ?'
//                     variant="cs-style1 text-center"
//                  />

//               <Spacing lg='90' md='45'/>
//             </Div>
//             <Div className='col-12 '>
//               {
//                 services.length>1?
//                 <Div className='row'>

//                 {displayServices}
//               </Div>

//                 :
//                 <Div className='container' style={{ width: 200, height: 200 }}>
//                 <AnimatedCircularProgress percentage={progress} />
//                 </Div>
//               }

//             </Div>
//           </Div>
//         </Div>

//       <Spacing lg='130' md='80'/>
//       <Div className="container">
//       <Cta
//           title="Let's discuss making  <br />something <i>cool</i>"
//           btnText="Contact Us"
//           btnLink="/contact"
//           bgSrc="/images/cta_bg_3.jpeg"
//         />
//       </Div>
//       <ScrollToTop smooth  className='scrolltotop' color='#b7346c' width="20" height="20" viewBox="0 0 20 20"  xmlns="http://www.w3.org/2000/svg" svgPath= "M0 10L1.7625 11.7625L8.75 4.7875V20H11.25V4.7875L18.225 11.775L20 10L10 0L0 10Z"/>
//     </>
//   )

// }
const queryClient = new QueryClient();

const fetchServices = async ({ queryKey }) => {
  const [_key, options] = queryKey;
  const response = await serviceApi.getServices(options);
  return response.data.slice().reverse();
};

function ServicesComponent() {
  const options = {
    sort: "-createdAt",
    page: 1,
    limit: 200,
  };

  const [progress, setProgress] = useState(20);

  const { data: services = [], error, isLoading } = useQuery({
    queryKey: ['services', options],
    queryFn: fetchServices,
  });

  const displayServices = services.map((item, index) => (
    <Div key={index} className="col-12 col-md-6 col col-lg-4 col-sm-12">
      <Div className="container">
        <Card
          _id={item._id}
          imageCover={item.imageCover}
          title={item.title}
          description={item.description}
        />
        {services.length > index + 1 && <Spacing lg='95' md='60' />}
      </Div>
    </Div>
  ));

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 40));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // if (isLoading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;

  return (
   <>
     <Helmet >
       <title>{pageTitle('Services')}</title>
       <meta property="og:title" content="Services" />
     <meta
        name="description"
    content="Convention marketing is defined as brand advertising and attendee engagement outside of trade shows. This type of marketing helps companies stand out at busy trade shows or conferences. It can take on many forms ranging from mobile billboards to guerilla marketing activities." />
    </Helmet>
      <PageHeading
        title='Services'
        bgSrc='images/service_hero_bg.jpeg'
        pageLinkText='Services'
      />
      <Spacing lg='30' md='30'/>

        <Div className="container">
          <Div className="row">
            <Div className="col-12">
                <SectionHeading
                  title='Services we can help you with'
                  subtitle='What Can We Do ?'
                    variant="cs-style1 text-center"
                 />

              <Spacing lg='90' md='45'/>
            </Div>
            <Div className='col-12 '>
              {
                services.length>1?
                <Div className='row'>

                {displayServices}
              </Div>

                :
                <Div className='container' style={{ width: 200, height: 200 }}>
                <AnimatedCircularProgress percentage={progress} />
                </Div>
              }

            </Div>
          </Div>
        </Div>

      <Spacing lg='130' md='80'/>
      <Div className="container">
      <Cta
          title="Let's discuss making  <br />something <i>cool</i>"
          btnText="Contact Us"
          btnLink="/contact"
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <ScrollToTop smooth  className='scrolltotop' color='#b7346c' width="20" height="20" viewBox="0 0 20 20"  xmlns="http://www.w3.org/2000/svg" svgPath= "M0 10L1.7625 11.7625L8.75 4.7875V20H11.25V4.7875L18.225 11.775L20 10L10 0L0 10Z"/>
    </>
  );
}

export default function ServicesPage() {
  return (
    <QueryClientProvider client={queryClient}>
      <ServicesComponent />
    </QueryClientProvider>
  );
}
