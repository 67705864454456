import React from 'react'
import { Link } from 'react-router-dom'
import Div from '../Div'
import './post.scss'

export default function Post({imageCover, name,description,_id}) {
  return (
    <Div className="cs-post cs-style1">
      <Link  to={`/events/${_id}`} className="cs-post_thumb">
        <img src={imageCover}  alt={name}/>
        <Div className="cs-post_overlay" />
      </Link>
      <Div className="cs-post_info">
        <h2 className="cs-post_title"><Link  to={`/events/${_id}`}>{name}</Link></h2>
        <Div className="cs-posted_by">{description}</Div>
      </Div>
    </Div>
  )
}
