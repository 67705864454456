

const Validation = ({ userData }) => {
  const { customerName, customerEmail, customerPhone } = userData;
  const errors = {};

  if (!customerName.trim()) {
    errors.customerName = "Name is required";
  }

  if (!customerPhone.trim()) {
    errors.customerPhone = "Mobile is required";
  }

  if (!customerEmail.trim()) {
    errors.customerEmail = "Email is required";
  } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(customerEmail)) {
    errors.customerEmail = "Email is not valid";
  }

  return errors;
};

export default Validation;
