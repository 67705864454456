
import axiosApi from '../api'

export default {
    async getEvents(params) {
      return await axiosApi
        .get(`events`, { params: params })
        .then(res => {
          console.log(res.data + "this is data ")

          return res.data
        })
        .catch(e => {
          console.log(
            JSON.stringify(e.response?.data?.errors) + "this is an error ",
          )

          return e.response?.data?.errors
        })
    },

    async getEvent(id) {
      return await axiosApi
        .get(`events/${id}`)
        .then(res => {
          console.log(res.data + "this is data ")

          return res.data
        })
        .catch(e => {
          console.log(
            JSON.stringify(e.response?.data?.errors) + "this is an error ",
          )

          return e.response?.data?.errors
        })
    },
}
