import VisionlineSlider from '../Slider/VisionTimeLine';
import Div from '../Div';
import SectionHeading2 from '../SectionHeading/sectionHeading2';
import Spacing from '../Spacing';

export default function VisionAndMission() {
    return(
            <Div className="cs-shape_wrap_2">
                    <Div className="cs-shape_2">
                    <Div />
                    </Div>
                    <Div className="container">
                    <Div className="row">
                        <Div className="col-xl-4">
                        <SectionHeading2
                            title=""
                            subtitle="Our Vision & Mission"
                            variant="cs-style16"
                        />
                        <Spacing lg="90" md="45" />
                        </Div>
                        <Div className="col-xl-7 offset-xl-1">
                        <VisionlineSlider />
                        </Div>
                    </Div>
                    </Div>
                </Div>
      )
}