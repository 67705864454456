import Hero6 from "../Hero/Hero6";
export default function HomeSlider(){
    const heroSocialLinks = [
        {
          name: 'Instagram',
          links: 'https://www.instagram.com/pbcegy/',
        },
        {
          name: 'Facebook',
          links: 'https://www.facebook.com/pbcegy/',
        },
        {
          name: 'Linkedin',
          links: 'https://www.linkedin.com/company/pbcegy/',
        },
      ];
    
      const showcaseData = [
        {
          title: 'Break through <br/> the noise',
          imgUrl: '/images/slider_1.webp',
          href: '',
        },
        {
          title: 'From concept to <br/> execution',
          imgUrl: '/images/slider_2.webp',
          href: '',
        },
        {
          title: 'Elevating your <br/> business to new  <br/> heights',
          imgUrl: '/images/slider_3.webp',
          href: '',
        },
    
      ];
      return(
        <>
      <Hero6
        heroSocialLinks={heroSocialLinks}
        showcaseData={showcaseData}
        />
        
        </>
      )
}