import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import SocialWidget from '../Widget/SocialWidget';
import Newsletter from '../Widget/Newsletter';
import './header.scss';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import Div from '../Div';

const HeaderNavLink = ({ to, onClick, children }) => (
  <NavLink to={to} onClick={onClick}>
    {children}
  </NavLink>
);

export default function Header({ variant }) {
  const [isSticky, setIsSticky] = useState(false);
  const [sideHeaderToggle, setSideHeaderToggle] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setIsSticky(window.scrollY > 0);
    });
  }, []);

  return (
    <>
      <header className={`cs-site_header cs-style1 text-uppercase ${variant || ''} cs-sticky_header ${isSticky ? 'cs-sticky_header_active' : ''}`}>
        <Div className="cs-main_header">
          <Div className="container">
            <Div className="cs-main_header_in">
              <Div className="cs-main_header_left">
                <Link className="cs-site_branding" to="/">
                  <img src="/images/logo.png" alt="Logo" width="100%" height="100%" />
                </Link>
              </Div>
              <Div className="cs-main_header_center">
                <Div className="cs-nav cs-primary_font cs-medium">
                  <ul className="cs-nav_list" style={{ display: mobileToggle ? 'block' : 'none' }}>
                    <li><HeaderNavLink to="/" onClick={() => setMobileToggle(false)}>Home</HeaderNavLink></li>
                    <li><HeaderNavLink to="about" onClick={() => setMobileToggle(false)}>About</HeaderNavLink></li>
                    <li><HeaderNavLink to="services" onClick={() => setMobileToggle(false)}>Services</HeaderNavLink></li>
                    <li><HeaderNavLink to="portfolio" onClick={() => setMobileToggle(false)}>Portfolio</HeaderNavLink></li>
                    <li><HeaderNavLink to="events" onClick={() => setMobileToggle(false)}>Events</HeaderNavLink></li>
                    <li><HeaderNavLink to="news" onClick={() => setMobileToggle(false)}>News</HeaderNavLink></li>
                    <li><HeaderNavLink to="contact" onClick={() => setMobileToggle(false)}>Contact</HeaderNavLink></li>
                  </ul>
                  <span className={`cs-munu_toggle ${mobileToggle ? 'cs-toggle_active' : ''}`} onClick={() => setMobileToggle(!mobileToggle)}>
                    <span></span>
                  </span>
                </Div>
              </Div>
              <Div className="cs-main_header_right">
                <Div className="cs-toolbox">
                  <span className="cs-icon_btn" onClick={() => setSideHeaderToggle(!sideHeaderToggle)}>
                    <span className="cs-icon_btn_in">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </span>
                  </span>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </header>

      <Div className={`cs-side_header ${sideHeaderToggle ? 'active' : ''}`}>
        <button className="cs-close" onClick={() => setSideHeaderToggle(!sideHeaderToggle)} />
        <Div className="cs-side_header_overlay" onClick={() => setSideHeaderToggle(!sideHeaderToggle)} />
        <Div className="cs-side_header_in">
          <Div className="cs-side_header_shape" />
          <Link className="cs-site_branding" to="/">
            <img src="/images/footer_logo.png" width="100%" height="100%" alt="Logo" />
          </Link>
          <Div className="cs-side_header_box">
            <h2 className="cs-side_header_heading">Do you have a project in<br /> mind? Keep connect us.</h2>
          </Div>
          <Div className="cs-side_header_box">
            <ContactInfoWidget title="Contact Us" withIcon />
          </Div>
          <Div className="cs-side_header_box">
            <Newsletter
              title='Request Company Profile'
              subtitle='Keep up with our latest news and events. Subscribe to our newsletter.'
              placeholder='Business Email'
              placeholderFirstName='First Name'
              placeholderLastName='Last Name'
            />
          </Div>
          <Div className="cs-side_header_box">
            <SocialWidget />
          </Div>
        </Div>
      </Div>
    </>
  );
}
