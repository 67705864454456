import React from 'react'
import Div from '../Div'
import OrderForm from './OrderForm'
import ShowAllEvents from './ShowAllEvents';
export default function OrderModal({handleSubmitForm,handleChange,errors,sections,items,setEventItems,order}){
    return(
        <form noValidate className=" mt-5"  onSubmit={handleSubmitForm}>
        <Div className='accordion-event '>
        {sections.map((section,index) => (
        <Div   onClick={() => {
            setEventItems([
            ...section.items
            ]);
         }}>
        <button className="content-event " type='button'>
        <label  class="container checkbox-event mt-3"  >
                <input  type="checkbox"
                className='input_checked'
                style={{width:"15px",height:"15px" }}
                onChange={(e)=>
                {if(e.target.checked)
                    order.eventSections.push(section._id);
                else
                order.eventSections.splice(index,1)
                } }
                />
                <span className="checkmark" style={{backgroundColor:"white"}} ></span>
            </label>
            <p className=" event-name mt-3 " >
            {section.name}
            </p>
        </button>
    </Div>
        ))
          }
        </Div>

        <ShowAllEvents
        items={items}
        order={order}
        />
        <OrderForm 
        handleChange={handleChange}
        errors={errors}
        order={order}
        />
      </form>
        
    )
}