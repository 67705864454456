import React from 'react'
import Div from '../Div'
import Spacing from '../Spacing'
import { Icon } from '@iconify/react'
export default function OrderForm ({handleChange,errors,order})
{
    return(
        <Div className="container">

        <Div className="row" style={{ paddingTop:"40px"}} >
  
        <Div className="col-sm-6">
         <label className="cs-primary_color" style={{fontSize:"20px"}}>Name<span className='text-danger'>*</span></label>
         <input  type="text" className="cs-form_field mb-2" onChange={handleChange} required  name='customerName' value={order.customerName} />
          {errors.customerName && <p className='text-danger'>{errors.customerName}</p>}
        <Spacing lg="20" md="20" />
  
         </Div>
        <Div className="col-sm-6">
          <label className="cs-primary_color" style={{fontSize:"20px"}}>Email<span className='text-danger'>*</span></label>
          <input type="email" className="cs-form_field mb-2"   name='customerEmail' onChange={handleChange} required  value={order.customerEmail}/>
          {errors.customerEmail && <p className='text-danger'>{errors.customerEmail}</p>}
          <Spacing lg="20" md="20" />
        </Div>
  
        <Div className="col-sm-6 ">
          <label className="cs-primary_color" style={{fontSize:"20px"}}>Phone<span className='text-danger'>*</span></label>
          <input type="text" className="cs-form_field mb-2"  name='customerPhone' onChange={handleChange} required  value={order.customerPhone} />
          {errors.customerPhone && <p className='text-danger'>{errors.customerPhone}</p>}
          <Spacing lg="20" md="20" />
        </Div>
  
        <Div className="col-sm-6" style={{marginTop:"40px"}}>
          <button className="cs-btn cs-style1" id='btn2' style={{width:"200px"}}>
            <span style={{fontSize:"20px"}}>Send</span>
            <Icon icon="bi:arrow-right" />
          </button>
  
        </Div>
  
        </Div>
        </Div>
    )
}