import PostList from '../Post/PostList';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';


export default function NewsSection(){
    return(
        <>
      <Div className="cs-shape_wrap_4">

       <Spacing lg="145" md="80" />
       <Div className="container">
         <Div className="row">
           <Div className="col-lg-5 col-xl-4 news-section">

             <SectionHeading
               title="Explore our recent news"
               subtitle=""
               btnText="View More News"
               btnLink="/news"
             />
             <Spacing lg="145" md="45" />
           </Div>
           <Div className="col-lg-7 offset-xl-1">
             <PostList />
           </Div>
         </Div>
       </Div>
     </Div>

        </>
    )
}
