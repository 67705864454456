import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../helper'
import Cta from '../components/Cta'
import Div from '../components/Div'
import Spacing from '../components/Spacing'
import orderApi from '../apiServices/ordersApi'
import eventsApi from '../apiServices/eventsApi'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import AnimatedCircularProgress from '../components/CircularProgress';
import clientApi from '../apiServices/clientApi'
import { Helmet } from 'react-helmet'
import ScrollToTop from "react-scroll-to-top";
import ContactModal from '../components/EventsSection/ContactModal'
import Validation from '../components/Validation';
import OrderValidation from '../components/Validation/OrderValidation'
import OrderModal from '../components/EventsSection/OrderModal'
export default function EventDetails() {
  const { eventId } = useParams();
  const [event, setEvent] = useState({});
  const [sections, setEventSections] = useState([]);
  const [items, setEventItems] = useState([]);
  const [smShow, setSmShow] = useState(false);
  const [contentShow, setContentShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [progress, setProgress] = useState(20);
  const [order, setOrder] = useState({
    customerName: "",
    customerEmail: "",
    customerPhone: "",
    event: eventId,
    eventSections: [],
    items: []
  });
  const [contact, setContact] = useState({
    firstName: "",
    lastName: "",
    businessEmail: "",
    firstPhone: "",
    message: "",
    type: "registered"
  });
  const handleChange = (e) => {
    const value = e.target.value;
    setOrder(prevOrder => ({ ...prevOrder, [e.target.name]: value }));
    setContact(prevContact => ({ ...prevContact, [e.target.name]: value }));
  };
  const handleContactChange = (e) => {
    const value = e.target.value;
    setContact(prevContact => ({ ...prevContact, [e.target.name]: value }));
  };
  const handleSubmitForm=(e)=>{
    e.preventDefault();
    const userData={
      firstName:order.customerName,
      lastName:order.customerName,
      businessEmail:order.customerEmail,
      firstPhone:order.customerPhone,
      message:`Thanks,${order.customerName}.Your Order Sent Successfully`,
      type:"registered"
    }
    const validationErrors = OrderValidation({ userData: order });
    setErrors(validationErrors);
    clientApi.contact(userData)
    .then((response) => {
      console.log(response.data)
      setContentShow(true)
      setContentShow(false)
    })
    .catch(error => {
      console.error(error);
    });
    orderApi.makeOrder(order)
    .then((response) => {
      console.log(response.data)
      setSmShow(true)
    })
    .catch(error => {
      console.error(error);
    });
  }
  const handleSubmitContactForm=(e)=>{
    e.preventDefault();
    const userData={
      lastName:contact.lastName,
      firstName:contact.firstName,
      businessEmail:contact.businessEmail,
      firstPhone:contact.firstPhone,
      type:contact.type,
      message:`Thanks for your interst in,${event.name}.`
    }
    setErrors(Validation({userData}))
    clientApi.contact(userData)
    .then((response) => {
      console.log(response.data)
      setContentShow(false)
    })
    .catch(error => {
      console.error(error);
    });
  }
  useEffect(() => {
    eventsApi.getEvent(eventId)
      .then(response => {
        setEvent(response.data);
        setEventSections(response.data.eventSections);
      })
      .catch(error => {
        console.error(error);
      });
    const interval = setInterval(() => {
      setProgress(prevProgress => (prevProgress >= 100 ? 0 : prevProgress + 40));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
    <Helmet >
        <title>{pageTitle('Event Details')}</title>
        <meta property="og:title" content=" Details" />
        <meta
          name="description"
          content={`Details of ${event.name} `}
        />
      </Helmet>
      <Spacing lg="100" md="80" />
      {/* <ContactModal
        handleSubmitContactForm={handleSubmitContactForm}
        handleContactChange={handleContactChange}
        contentShow={contentShow}
        setContentShow={setContentShow}
        contact={contact}
        errors={errors}
      /> */}
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        animation="true">
        <Modal.Header closeButton className='modal-btn'>
          <Modal.Title id="example-modal-sizes-title-sm" className='text-light text-center' style={{ fontSize: "16px" }} >
            Your Quotation Sent Successfully
          </Modal.Title>
        </Modal.Header>
      </Modal>
      {!contentShow ?
        <Div className=" mt-4">
          <h2 className='text-center'>{event.name}</h2>
          <h5 className='text-center' style={{ color: "#999696" }}>Request A Quotation?</h5>
          <OrderModal
            handleSubmitForm={handleSubmitForm}
            handleChange={handleChange}
            errors={errors}
            sections={sections}
            items={items}
            setEventItems={setEventItems}
            order={order}
          />
        </Div>:
        <Div className='container' style={{ width: 200, height: 200 }}>
          <AnimatedCircularProgress percentage={progress} />
        </Div>}
      <Spacing lg='150' md='80' />
      <Div className="container">
        <Cta
          title="Let's discuss making  <br />something <i>cool</i>"
          btnText="Contact Us"
          btnLink="/contact"
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <ScrollToTop smooth className='scrolltotop' width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" svgPath="M0 10L1.7625 11.7625L8.75 4.7875V20H11.25V4.7875L18.225 11.775L20 10L10 0L0 10Z" />
    </>
  )
}
