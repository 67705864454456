import React from 'react'
import Div from '../Div'
import './timeline.scss'

export default function Timeline({columnData}) {
  return (
    <>
      {columnData.map((item,index)=>(
        <Div className="cs-time_line cs-style1" key={index}>
          <h3 className="cs-accent_color">{item.year}</h3>
          <h2 className='text-center'>{item.name}</h2>
          <p style={{width:"89%",textAlign:"justify",hyphens: "auto"}} >{item.position}</p>
          <p className='text-justify'>{item.type}</p>
        </Div>
      ))}
    </>
  )
}
