import React from 'react'
import Div from '../Div'
import './card.scss'

export default function NewsCard({imageCover}) {
  return (
    <Div className='container'>
    <Div className="cs-card cs-style1 d-flex" style={{width:'260px',height:'280px'}}>
      <>
        <img src={imageCover}  />


      </>
    </Div>
    </Div>
  )
}
