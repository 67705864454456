import React from 'react';
import { pageTitle } from '../helper';
import Cta from '../components/Cta';
import PageHeading from '../components/PageHeading';
import Div from '../components/Div';
import SectionHeading from '../components/SectionHeading';
import TeamSlider from '../components/Slider/TeamSlider';
import Spacing from '../components/Spacing';
import { Helmet } from 'react-helmet';
import ScrollToTop from "react-scroll-to-top";
import AboutCompany from '../components/AboutSection/AboutCompany';
import OurFounder from '../components/AboutSection/OurFounder';
import VisionAndMission from '../components/AboutSection/VisionAndMission';
import Values from '../components/AboutSection/Values';

export default function AboutPage() {

  return (
    <>
    <Helmet >
      <title>{pageTitle('About')}</title>
      <meta property="og:title" content="About" />
    <meta
     name="description"
    content="Our distinguished team of marketers and designers are here to help you
     grow your business and boost your brand in the most appealing way! We offer packages of innovative 360 marketing solutions."
    />
    </Helmet>
      <PageHeading
        title="About Us"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="About Us"
      />
      <Spacing lg="100" md="80" />
      <AboutCompany/>

      <Spacing lg="100" md="80" />
      <OurFounder/>

      <Spacing lg="80" md="0" />
      <VisionAndMission/>
      <Values/>
      <Spacing lg="80" md="50" />

      <Spacing lg="50" md="50" />
      <Div className="container">
        <SectionHeading
          title="Awesome team <br/>members"
          subtitle="Our Team"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <TeamSlider />

      </Div>

      <Spacing lg="130" md="80" />
      <Div className="container">
      <Cta
          title="Let's discuss making  <br />something <i>cool</i>"
          btnText="Contact Us"
          btnLink="/contact"
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>

      <ScrollToTop smooth  className='scrolltotop' color='#b7346c' width="20" height="20" viewBox="0 0 20 20"  xmlns="http://www.w3.org/2000/svg" svgPath= "M0 10L1.7625 11.7625L8.75 4.7875V20H11.25V4.7875L18.225 11.775L20 10L10 0L0 10Z"/>
    </>
  );
}
