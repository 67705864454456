import ServiceList from '../ServiceList';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
export default function ServiceSection() {
    return(
        <>
         <Div className="container service-section">
        <SectionHeading
          title="Our core services"
          subtitle=""
          variant="cs-style1 text-center"

        />
        <Spacing lg="10" md="30" />
        <ServiceList />
        <Div className="cs-shape_wrap_13">
        <Div className="cs-shape_13"></Div>

        </Div>
        <SectionHeading
          title=""
          subtitle=""
          variant=""
          btnText="View More Services"
          btnLink="/services"

        />
      </Div>
      </>

 
    )
}