import React, { useEffect,useState } from 'react'
import { pageTitle } from '../helper'
import Cta from '../components/Cta'
import PageHeading from '../components/PageHeading'
import SectionHeading from '../components/SectionHeading';
import 'react-circular-progressbar/dist/styles.css';
import PostStyle2 from '../components/Post/PostStyle2'
import Div from '../components/Div'
import ReactPaginate from 'react-paginate';
import Spacing from '../components/Spacing'
import newsApi from '../apiServices/newsApi'
import { Helmet } from 'react-helmet'
import ScrollToTop from "react-scroll-to-top";
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AnimatedCircularProgress from '../components/CircularProgress';
// export default function NewsPage() {
//     const [news, setNews] = useState([]);
//     const options = {
//       sort: "-createdAt",
//       page: 1,
//       limit: 3,

//     }
//     const [pageNumber,setPageNumber]=useState(0)
//     const newsPerPage=8
//     const pageCount=Math.ceil(news.length/newsPerPage)
//     const handlePageClick=({selected})=>{
//       setPageNumber(selected)
//     }
//     const [progress, setProgress] = useState(20);
//     const pageVisited=pageNumber*newsPerPage
//     const displayNews=news.slice(pageVisited,pageVisited+newsPerPage).map((item, index)=> {
//       return(
//         <Div key={index} >
//         <PostStyle2
//           imageCover={item.imageCover}
//           title={item.title}
//           description={item.description}
//           href={`/news/${item._id}`}
//         />
//         {news.length>index+1 && <Spacing lg='95' md='60'/>}
//       </Div>

//   );
//       })
//     useEffect(() => {
//       newsApi.getNews(options)
//         .then(response => {
//           setNews(response.data);
//           console.log(response.data)
//         })
//         .catch(error => {
//           console.error(error);
//         });

//      const interval = setInterval(() => {
//       setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 40));
//     }, 1000);

//     return () => clearInterval(interval);
//     }, []);

//   return (
//     <>
//       <Helmet >
//    <title>{pageTitle('News')}</title>
//    <meta property="og:title" content="News" />
//     <meta
//          name="description"
//         content="News and their details" />
//     </Helmet>
//       <PageHeading
//         title='Our News'
//         bgSrc='/images/blog_hero_bg.jpeg'
//         pageLinkText='News'
//       />
//          <Spacing lg='30' md='30'/>
//         <SectionHeading
//             title='News'
//              subtitle=''
//              variant="cs-style1 text-center"
//                  />
//        <Spacing lg='90' md='45'/>
//       {news.length>0?
//       <Div className="container">
//     <Div className='news' >
//          {displayNews}
//        </Div>
//           <Spacing lg='60' md='40'/>

//            <Div className='pagination-service'>
//                <ReactPaginate
//                     breakLabel="..."
//                     nextLabel=">"
//                     onPageChange={handlePageClick}
//                     pageCount={pageCount}
//                     previousLabel="<"
//                     containerClassName={'paginationBttns'}
//                     pageLinkClassName={'previousBttn'}
//                     nextLinkClassName={'nextBttn'}
//                     disabledClassName={'paginationDisabled'}
//                     activeClassName={'paginationActive'}
//                />
//               </Div>
//         </Div>:
//        <Div className='container' style={{ width: 200, height: 200 ,marginTop:40}}>
//          <AnimatedCircularProgress percentage={progress} />
//      </Div>}
//       <Spacing lg='150' md='80'/>
//       <Div className="container">
//      <Cta
//           title="Let's discuss making  <br />something <i>cool</i>"
//           btnText="Contact Us"
//           btnLink="/contact"
//           bgSrc="/images/cta_bg_3.jpeg"
//         />
//       </Div>
//       <ScrollToTop smooth  className='scrolltotop' color='#b7346c' width="20" height="20" viewBox="0 0 20 20"  xmlns="http://www.w3.org/2000/svg" svgPath= "M0 10L1.7625 11.7625L8.75 4.7875V20H11.25V4.7875L18.225 11.775L20 10L10 0L0 10Z"/>
//     </>
//   )
// }
const queryClient = new QueryClient();

const fetchNews = async ({ queryKey }) => {
  const [_key, options] = queryKey;
  const response = await newsApi.getNews(options);
  return response.data;
};

function NewsComponent() {
  const options = {
    sort: "-createdAt",
    page: 1,
    limit: 3,
  };

  const [pageNumber, setPageNumber] = useState(0);
  const newsPerPage = 8;

  const { data: news = [], error, isLoading } = useQuery({
    queryKey: ['news', options],
    queryFn: fetchNews,
  });

  const pageCount = Math.ceil(news.length / newsPerPage);
  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  const [progress, setProgress] = useState(20);
  const pageVisited = pageNumber * newsPerPage;

  const displayNews = news.slice(pageVisited, pageVisited + newsPerPage).map((item, index) => (
    <Div key={index}>
      <PostStyle2
        imageCover={item.imageCover}
        title={item.title}
        description={item.description}
        href={`/news/${item._id}`}
      />
      {news.length > index + 1 && <Spacing lg='95' md='60' />}
    </Div>
  ));

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 40));
    }, 1000);
    return () => clearInterval(interval);
  }, []);



  return (
    <>
      <Helmet>
        <title>{pageTitle('News')}</title>
        <meta property="og:title" content="News" />
        <meta
          name="description"
          content="News and their details"
        />
      </Helmet>
      <PageHeading
        title='Our News'
        bgSrc='/images/blog_hero_bg.jpeg'
        pageLinkText='News'
      />
      <Spacing lg='30' md='30' />
      <SectionHeading
        title='News'
        subtitle=''
        variant="cs-style1 text-center"
      />
      <Spacing lg='90' md='45' />
      {news.length > 0 ? (
        <Div className="container">
          <Div className='news'>
            {displayNews}
          </Div>
          <Spacing lg='60' md='40' />
          <Div className='pagination-service'>
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageCount={pageCount}
              previousLabel="<"
              containerClassName={'paginationBttns'}
              pageLinkClassName={'previousBttn'}
              nextLinkClassName={'nextBttn'}
              disabledClassName={'paginationDisabled'}
              activeClassName={'paginationActive'}
            />
          </Div>
        </Div>
      ) : (
        <Div className='container' style={{ width: 200, height: 200, marginTop: 40 }}>
          <AnimatedCircularProgress percentage={progress} />
        </Div>
      )}
      <Spacing lg='150' md='80' />
      <Div className="container">
        <Cta
          title="Let's discuss making  <br />something <i>cool</i>"
          btnText="Contact Us"
          btnLink="/contact"
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <ScrollToTop
        smooth
        className='scrolltotop'
        color='#b7346c'
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        svgPath="M0 10L1.7625 11.7625L8.75 4.7875V20H11.25V4.7875L18.225 11.775L20 10L10 0L0 10Z"
      />
    </>
  );
}

export default function NewsPage() {
  return (
    <QueryClientProvider client={queryClient}>
      <NewsComponent />
    </QueryClientProvider>
  );
}
