
import axiosApi from '../api'

export default {

    async makeOrder(order) {
      return await axiosApi
        .post(`orders`, order)
        .then(res => {
          console.log(res.data + "this is data ")

          return res.data
        })
        .catch(e => {
          console.log(
            JSON.stringify(e.response?.data?.errors) + "this is an error ",
          )

          return e.response?.data?.errors
        })
    },


}
