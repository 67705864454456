import React from 'react';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

export default function Button2({ btnLink, btnText, variant, icon }) {
  return (
    <Link
      to={btnLink}
      className="btn btn2 btn-light"
    >
      <>
        <span className='mx-2'>{btnText}</span>
        {icon ? icon : <Icon icon="bi:arrow-right" />}
      </>
    </Link>
  );
}
