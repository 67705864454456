import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../Button'
import Div from '../Div'
import './post.scss'

export default function PostStyle2({imageCover, title,description,day,href,month,year,event}) {
  return (
    
     
    <Div className="cs-post cs-style2 ">
      <Link to={href} className="cs-post_thumb cs-radius_15">
        <img src={imageCover} alt="Post" className="w-100 cs-radius_15" />
      </Link>
      <Div className="cs-post_info">
        <Div className="cs-post_meta  cs-ternary_color cs-semi_bold cs-primary_font">
          <span className='event-post'>{event}</span>
        </Div>
        <h2 className="cs-post_title"><Link to={href}>{title}</Link></h2>
        <Div className="cs-post_sub_title">{description}</Div>
        <Button btnLink={href} btnText='See More' />
      </Div>
    </Div>
 
   
  )
}
