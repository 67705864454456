import React from 'react';
import newsApi from '../../apiServices/newsApi'
import { useState,useEffect } from 'react';
import Div from '../Div';
import AnimatedCircularProgress from '../CircularProgress';
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';


const queryClient = new QueryClient();

const fetchNews = async (options) => {
  const response = await newsApi.getNews(options);
  return response.data.slice().reverse();
};

function PostList() {
  const options = {
    sort: "createdAt",
    page: 1,
    limit: 3,
  };

  const { data: news = [], error, isLoading } = useQuery({
    queryKey: ['news', options],
    queryFn: () => fetchNews(options),
  });

  const [progress, setProgress] = useState(20);

  useEffect(() => {
    // Simulate progress update (you can replace this with your actual logic)
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 40));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <ul className="cs-post_3_list cs-mp0">
        {isLoading ? (
          <Div className='container' style={{ width: 200, height: 200 }}>
            <AnimatedCircularProgress percentage={progress} />
          </Div>
        ) : (
          news.map((item, index) => (
            <li key={index}>
              <div className="cs-post cs-style3">
                <div className="cs-post_left">
                  <img src={item.imageCover} alt={item.title} className='imgNews' />
                </div>
                <div className="cs-post_right">
                  <h2 className="cs-post_title">
                    <a href={`/news/${item._id}`}>{item.title}</a>
                  </h2>
                  <div className="cs-post_subtitle">{item.description}</div>
                  <div className="cs-post_meta">
                    <a href={`/news/${item._id}`} className="cs-text_btn">
                      <span>Read More</span>
                    </a>
                  </div>
                </div>
              </div>
            </li>
          ))
        )}
      </ul>
    </>
  );
}

export default function PostListComponent() {
  return (
    <QueryClientProvider client={queryClient}>
      <PostList />
    </QueryClientProvider>
  );
}
