import { Route, Routes } from 'react-router-dom';
import AboutPage from './Pages/AboutPage';
import EventsPage from './Pages/EventsPage';
import EventDetails from './Pages/EventDetails';
import ContactPage from './Pages/ContactPage';
import ErrorPage from './Pages/ErrorPage';
import ServiceDetailsPage from './Pages/ServiceDetailsPage';
import ServicesPage from './Pages/ServicesPage';
import PortfolioPage from './Pages/PortfolioPage';
import MarketingAgencyHome from './Pages/Home';
import Layout from './components/Layout';
import NewsPage from './Pages/NewsPage'
import NewsDetailsPage from './Pages/NewsDetailsPage';

function App() {
  return (
    <>

      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<MarketingAgencyHome />} />


          <Route path="about" element={<AboutPage />} />
          <Route path="services" element={<ServicesPage />} />
          <Route
            path="services/:serviceId"
            element={<ServiceDetailsPage />}
          />
          <Route path="portfolio" element={<PortfolioPage/>} />

          <Route path="events" element={<EventsPage />} />
          <Route path="events/:eventId" element={<EventDetails/>} />
          <Route path="contact" element={<ContactPage />} />

          <Route path="news" element={<NewsPage/>}/>
          <Route path="news/:blogId" element={<NewsDetailsPage/>}/>



        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
