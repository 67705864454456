import React from 'react'
import Div from '../Div'
import { useState } from 'react';
import clientApi from '../../apiServices/clientApi'
import Modal from 'react-bootstrap/Modal';
import Validation from '../Validation';
export default function Newsletter({title, subtitle, placeholder,placeholderFirstName,placeholderLastName}) {
  const [subscribe, setSubscribe] = useState({
    firstName:"",
    lastName:"",
    businessEmail:"",
    firstPhone:"010293432",
    message:"hello",
    type:"registered"
  });
  const [smShow, setSmShow] = useState(false);
  const [errors,setErrors]=useState({})
  const handleChange=(e)=>{
    const value=e.target.value;
    setSubscribe({
      ...subscribe,
      [e.target.name]:value
    })

  }
  const handleSubmitForm=(e)=>{
    e.preventDefault();
    const userData={
      lastName:subscribe.lastName,
      firstName:subscribe.firstName,
      businessEmail:subscribe.businessEmail,
      firstPhone:subscribe.firstPhone,
      message:subscribe.message,
      type:subscribe.type

    }
    setErrors(Validation({userData}))
     clientApi.subscribe(userData)
    .then((response) => {

      console.log(response.data)
      setSmShow(true)
    })
    .catch(error => {
      console.error(error);

    });

  }
  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <Div className="cs-newsletter cs-style1">
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        animation="true"
      >
        <Modal.Header closeButton className='modal-btn'>
          <Modal.Title id="example-modal-sizes-title-sm" className='text-light text-center'style={{fontSize:"16px"}} >
            Subscribed successfully
          </Modal.Title>
        </Modal.Header>
      </Modal>
        <form noValidate className="cs-newsletter_form" onSubmit={handleSubmitForm}>
        <input type="text" className={`cs-newsletter_input mb-2` } onChange={handleChange}  required placeholder={placeholderFirstName} name='firstName' value={subscribe.firstName} />
         {errors.firstName && <p className='text-danger'>{errors.firstName}</p>}
        <input type="text" className="cs-newsletter_input mb-2" onChange={handleChange}  required placeholder={placeholderLastName} name='lastName' value={subscribe.lastName}  />
        {errors.lastName && <p className='text-danger'>{errors.lastName}</p>}

         <input type="email" className="cs-newsletter_input mb-3" required  onChange={handleChange}  placeholder={placeholder} name='businessEmail' value={subscribe.businessEmail}/>
         {errors.businessEmail && <p className='text-danger'>{errors.businessEmail}</p>}
          <div className='container'></div>
          <button type='submit' className="btn btn-sm btn-submit" ><span>Send</span></button>
        </form>


        <Div className="cs-newsletter_text">{subtitle}</Div>
      </Div>
    </>
  )
}
