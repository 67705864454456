import React from 'react'
import Div from '../Div'
 
 export default function ShowAllEvents({items,order}){
    return(
        <ul>

        <Div className='row mt-4'>
        <Div className="items-img">

        {items.map((detail,index) => (
               <>
            <Div key={index} className="container-item">
                <Div className="container_img_event">
                <img src={detail.imageCover} alt={detail.name} />
                </Div>
           <label className="container checkbox-event mt-2  "  >
            <input className='input_checked2'   style={{width:"20px",height:"20px" }} type="checkbox" value={detail._id} onChange={(e)=>

              {

              if(e.target.checked) {order.items.push({item:detail._id,quantity:1})}
              else order.items= order.items.filter(e => e.item !== detail._id)

              } }  />

            <span class="checkmark"></span>
            </label>

          {
            detail.countable?
            <Div className="d-flex mt-1">
            <label for="quantity" className='mx-4 text-white mt-2' style={{fontSize:"20px"}}>Quantity</label>
            <input type='number' className='cs-form_field w-50'  placeholder='1' defaultValue={1} style={{color:"white"}} min="1" onChange={(e)=>{order.items[index].quantity=e.target.value}}  />
            </Div>
            :''
          }
          </Div>
          </>
          ))}
        </Div>
        </Div>

      </ul>
    )
 }