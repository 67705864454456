import React from 'react'
import Div from '../Div'
import './logolist.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState,useEffect } from 'react';
// Import Swiper styles
import AnimatedCircularProgress from '../CircularProgress/';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import 'swiper/css';
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import logosApi from '../../apiServices/logosApi';


const queryClient = new QueryClient();

const fetchLogos = async (options) => {
  const response = await logosApi.getLogos(options);
  return response.data.slice().reverse();
};

function LogoList() {
  const options = {
    page: 1,
    limit: 1000,
  };

  const { data: logos = [], error, isLoading } = useQuery({
    queryKey: ['logos', options],
    queryFn: () => fetchLogos(options),
  });

  const [progress, setProgress] = useState(20);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 40));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // if (isLoading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;

  return (
    <Div className="cs-partner_logo_wrap">
      <Swiper
       modules={[Navigation, Pagination, Scrollbar, A11y,Autoplay]}
        spaceBetween={50}
        slidesPerView={4}
        slidesPerGroup={5}
        scrollbar={{ draggable: true }}
        speed={100}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
        loop={true}
        autoplay
        className='logosSwiper'
        breakpoints={{
          320: { spaceBetween: 20, slidesPerView: 5 },
          480: { spaceBetween: 30, slidesPerView: 5 },
          768: { spaceBetween: 40, slidesPerView: 5 },
          1024: { spaceBetween: 50, slidesPerView: 5 },
          1200: { spaceBetween: 70, slidesPerView: 5 }
        }}
      >
        {logos.map((partnerLogo, index) => (
          <SwiperSlide key={index}><img src={partnerLogo.imageCover} alt={partnerLogo.name} /></SwiperSlide>
        ))}

      {!logos.length && (
        <Div className='container' style={{ width: 200, height: 200 }}>
          <AnimatedCircularProgress percentage={progress} />
        </Div>

      )}
            </Swiper>
    </Div>
  );
}

export default function LogoListComponent() {
  return (
    <QueryClientProvider client={queryClient}>
      <LogoList />
    </QueryClientProvider>
  );
}
