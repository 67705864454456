
import axiosApi from '../api'

export default {
    async subscribe(subscribe) {
      return await axiosApi
        .post(`clients/subscribe`,subscribe )
        .then(res => {
          console.log(res.data + "this is data ")

          return res.data
        })
        .catch(e => {
          console.log(
            JSON.stringify(e.response?.data?.errors) + "this is an error ",
          )

          return e.response?.data?.errors
        })
    },

    async contact(contact) {
      return await axiosApi
        .post(`clients/contact`, contact)
        .then(res => {
          console.log(res.data + "this is data ")

          return res.data
        })
        .catch(e => {
          console.log(
            JSON.stringify(e.response?.data?.errors) + "this is an error ",
          )

          return e.response?.data?.errors
        })
    },


}
