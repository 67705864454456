import { Icon } from '@iconify/react';
import React from 'react';
import Slider from 'react-slick';
import Div from '../Div';
import Timeline from '../Timeline';

export default function TimelineSlider() {
  const timelineData = [
    [
      {
        year: 'Commitment',
        position: `We are pledged to give our time and energy to all our clients and to deliver all our work with high quality.`

      },
      {
        year: 'Knowledge Development',
        position: 'Everyday, we learn and develop our knowledge as individuals, as a team, and as an agency. '
      },
      {
        year: 'Speed',
        position: 'We prioritize the power of speed in driving success for our clients. Our dedicated team is built on a foundation of responsiveness and agility. '
      },



    ],
    [


      {
        year: 'Teamwork',
        position: 'We foster belonging and team spirit, leading to increased ownership and accountability for their work. '
      },
      {
        year: 'Creativity and Innovation',
        position:' Fostering innovation and embracing creativity distinguishes us, enabling impactful campaigns in a crowded marketplace.'


      },
      {
        year:'Integrity',
        position:'We are committed to ethical marketing and earning trust.Our certifications and industry affiliations reinforce our dedication to upholding the highest ethical standards.'
      }
      ,



    ],



  ];

  /** Slider Settings **/
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    arrows: false,
    responsive: [
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 1,

        },
      },

    ],
  };
  return (
    <Slider {...settings} className="cs-arrow_style3">
      {timelineData.map((item, index) => (
        <Div key={index}>
          <Timeline columnData={item} />
        </Div>
      ))}
    </Slider>
  );
}
