export default function Validation({userData})
{
    const validationErrors={}
    const emailPattern=/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ ;

    if(!userData.firstName.trim()){
        validationErrors.firstName="First Name is required"
      }
      if(!userData.lastName.trim()){
        validationErrors.lastName="Last Name is required"
      }
      if(!userData.firstPhone.trim()){
        validationErrors.firstPhone="Mobile is required"
      }
      if(!userData.message.trim()){
        validationErrors.message="Message is required"
      }
  
      if(!userData.businessEmail.trim()){
        validationErrors.businessEmail="Email is required"
      }
  
      else if(!emailPattern.test(userData.businessEmail)){
        validationErrors.businessEmail="Business Email is not valid"
      }
  
  
  return validationErrors

  
}
