import React from 'react';
import Cta from '../components/Cta';
import Div from '../components/Div';
import LogoList from '../components/LogoList';
import MovingText from '../components/MovingText';
import Spacing from '../components/Spacing';
import { Helmet } from 'react-helmet';
import VideoModal from '../components/VideoModal';
import { pageTitle } from '../helper';
import 'bootstrap/dist/css/bootstrap.css';
import HomeSlider from '../components/HomeSection/HomeSlider';
import ScrollToTop from "react-scroll-to-top";
import ServiceSection from '../components/HomeSection/ServiceSection';
import ActivationSection from '../components/HomeSection/ActivationSection';
import Hero from '../components/Hero';
import NewsSection from '../components/HomeSection/NewsSection';
export default function Home() {

  return (
    <>
     <Helmet >
   <title>{pageTitle('Home')}</title>
   <meta property="og:title" content="Home" />
    <meta
     name="description"
    content="Your home for 360 Marketing Solutions!
    We will put our marketing tools behind your idea, dream & brand to make a noise the world notices.
    Our distinguished team of marketers and designers are here to help you grow your business and boost your brand in the most appealing way!
    We offer packages of innovative 360 marketing solutions.
    To become pioneers in bringing companies and customers together, offer creative 360 marketing solutions plans to help our clients increase their brand awareness, customers’ reach and engagement, their return on investment, and present themselves as successful models in the market.

    " />
    </Helmet>

    <HomeSlider/>

      <Div className="cs-video_block_1_wrap">
        <Div className="container">
          <VideoModal
            videoSrc="https://www.youtube.com/watch?v=FoLY3bfVLPY"
            bgUrl="/images/Video_Cover.webp"
          />
        </Div>
      </Div>

      <Spacing lg="130" md="0" />

      <ServiceSection/>

      <Spacing lg="130" md="130" />
       <ActivationSection/>
       <NewsSection/>
      <Spacing lg="175" md="110" />
      <MovingText text="Our partners" />
      <Spacing lg="80" md="70" />

      <Div className="container">
        <Div className="row">
          <Div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <LogoList />
          </Div>
        </Div>
      </Div>
      <Spacing lg="90" md="80" />

      <Div className="container">
        <Cta
          title="Let's discuss making  <br />something <i>cool</i>"
          btnText="Contact Us"
          btnLink="/contact"
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>

      <ScrollToTop smooth  className='scrolltotop'  width="20" height="20" viewBox="0 0 20 20"  xmlns="http://www.w3.org/2000/svg" svgPath= "M0 10L1.7625 11.7625L8.75 4.7875V20H11.25V4.7875L18.225 11.775L20 10L10 0L0 10Z"/>

    </>
  );
}
