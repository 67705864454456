
import React from 'react';
import Div from '../Div';
import './portfolio.scss';

export default function Portfolio({ imageCover,variant }) {


  return (
    <Div

      className={`cs-portfolio ${variant ? variant : 'cs-style1'}`}
    >
      <>
       
        <Div
          className="cs-portfolio_bg cs-bg "
          style={{ backgroundImage: `url("${imageCover}")` }}

        />
    
      </>
    </Div>

  );
}
