import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../helper'
import Cta from '../components/Cta'
import PageHeading from '../components/PageHeading'
import Div from '../components/Div'
import Spacing from '../components/Spacing'
import { useState } from 'react'
import serviceApi from '../apiServices/servicesApi'
import { Helmet } from 'react-helmet';
import ScrollToTop from "react-scroll-to-top";
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
// export default function ServiceDetailsPage() {
//   const {serviceId}= useParams()
//   const [service, setService] = useState([]);
//   useEffect(() => {
//     serviceApi.getService(serviceId)
//       .then(response => {
//         setService(response.data);
//         console.log(response.data)
//         console.log(service)
//       })
//       .catch(error => {
//         console.error(error);
//       });
//   }, []);

//   return (
//     <>
//     <Helmet>
//      <title>{pageTitle('Service Details')}</title>
//      <meta property="og:title" content="Service Details" />
//     <meta
//        name="description"
//     content={`Details of ${service.title}-${service.description} `}
//      />
//     </Helmet>
//       <PageHeading
//         title='Service Details'
//         bgSrc='/images/service_hero_bg.jpeg'
//         pageLinkText={service.title}
//       />
//       <Div className="container">
//         <Spacing lg='100' md='45'/>
//         <Div className="row">
//           <Div  key={serviceId}>
//           <h2 className='text-center'>{service.title}</h2>
//           <img src={service.imageCover} alt="Details" className="cs-radius_15 mx-auto d-block " style={{ height:"40rem" }} />
//           <h5 className=' mt-3 paragraph'>{service.description}</h5>
//           </Div>
//         </Div>
//       </Div>
//       <Spacing lg='130' md='80'/>
//       <Div className="container">
//       <Cta
//           title="Let's discuss making  <br />something <i>cool</i>"
//           btnText="Contact Us"
//           btnLink="/contact"
//           bgSrc="/images/cta_bg_3.jpeg"
//         />
//       </Div>
//       <ScrollToTop smooth  className='scrolltotop' color='#b7346c' width="20" height="20" viewBox="0 0 20 20"  xmlns="http://www.w3.org/2000/svg" svgPath= "M0 10L1.7625 11.7625L8.75 4.7875V20H11.25V4.7875L18.225 11.775L20 10L10 0L0 10Z"/>
//     </>
//   )
// }
const queryClient = new QueryClient();

const fetchService = async (serviceId) => {
  const response = await serviceApi.getService(serviceId);
  return response.data;
};

function ServiceDetailsComponent() {
  const { serviceId } = useParams();

  const { data: service = {}, error, isLoading } = useQuery({
    queryKey: ['service', serviceId],
    queryFn: () => fetchService(serviceId),
  });

  // if (isLoading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <Helmet>
        <title>{pageTitle('Service Details')}</title>
        <meta property="og:title" content="Service Details" />
        <meta
          name="description"
          content={`Details of ${service.title}-${service.description}`}
        />
      </Helmet>
      <PageHeading
        title='Service Details'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={service.title}
      />
      <Div className="container">
        <Spacing lg='100' md='45' />
        <Div className="row">
          <Div key={serviceId}>
            <h2 className='text-center'>{service.title}</h2>
            <img src={service.imageCover} alt="Details" className="cs-radius_15 mx-auto d-block" style={{ height: "40rem" }} />
            <h5 className='mt-3 paragraph'>{service.description}</h5>
          </Div>
        </Div>
      </Div>
      <Spacing lg='130' md='80' />
      <Div className="container">
        <Cta
          title="Let's discuss making  <br />something <i>cool</i>"
          btnText="Contact Us"
          btnLink="/contact"
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <ScrollToTop smooth className='scrolltotop' color='#b7346c' width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" svgPath="M0 10L1.7625 11.7625L8.75 4.7875V20H11.25V4.7875L18.225 11.775L20 10L10 0L0 10Z" />
    </>
  );
}

export default function ServiceDetailsPage() {
  return (
    <QueryClientProvider client={queryClient}>
      <ServiceDetailsComponent />
    </QueryClientProvider>
  );
}
