import React from 'react'
import { Link } from 'react-router-dom'
import Div from '../Div'
import './card.scss'

export default function Card({imageCover, title,_id}) {
  return (
    <Div className='container'>
    <Link  className="cs-card cs-style1 d-flex" to={`/services/${_id}`} style={{width:'260px',height:'280px'}}>
      <>
        <img src={imageCover} alt={title} />
        <Div className="" />
        <Div className="cs-card_info_service">

          <span className=" cs-accent_bg" />
          <h4 className="cs-card_title_card">{title}</h4>
        </Div>
      </>
    </Link>
    </Div>
  )
}
