import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import './scss/index.scss';
import { Helmet } from 'react-helmet';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Helmet htmlAttributes>

     <html lang="en"/>
     <title>Point Blank Communications</title>

    <meta charset="utf-8" />

    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#ffffff" />
    <meta
      name="description"
      content="PBC- Point Blank Communications"
    />
    <meta name='keywords' content='Pbc,Point Blank Communications,Marketing,Agency,communications'/>

    </Helmet>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

