import React from 'react'
import Div from '../Div'
import ContactInfoWidget from '../Widget/ContactInfoWidget'
import MenuWidget from '../Widget/MenuWidget'
import Newsletter from '../Widget/Newsletter'
import SocialWidget from '../Widget/SocialWidget'
import TextWidget from '../Widget/TextWidget'
import './footer.scss'
import CopyRightFooter from '../Widget/CopyRightsFooter'
import servicesApi from '../../apiServices/servicesApi'
import { useState,useEffect } from 'react'
export default function Footer({copyrightText, logoSrc, logoAlt, text}) {
  const [services, setServices] = useState([]);
  const options = {
    sort: "-createdAt",
    page: 1,
    limit:20,


  }

  useEffect(() => {
    servicesApi.getServices(options)
      .then(response => {
        setServices(response.data.slice(8).reverse());
        console.log(response.data.slice(8).reverse())
      })
      .catch(error => {
        console.error(error);
      });
  }, []);
  const copyrightLinks = [
    {
      title: 'Terms of Use',
      href: '#'
    },
    {
      title: 'Privacy Policy',
      href: '#'
    }
  ]



  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget
                  logoSrc='/images/footer_logo.png'
                  logoAlt='Logo'
                  text ='Welcome to Point Blank Communications marketing agency.'
                />
                <SocialWidget/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={services} menuHeading='Services'/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget title='Contact Us'/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <Newsletter
                  title='Request Company Profile'
                  subtitle='Keep up with our latest news and events. Subscribe to our newsletter.'
                  placeholder='Business Email'
                  placeholderFirstName='First Name'
                  placeholderLastName='Last Name'
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">Copyright ©{new Date().getFullYear()} Point Blank Communications.</Div>
          </Div>
          <Div className="cs-bottom_footer_right">
            <CopyRightFooter menuItems={copyrightLinks} variant=' cs-style2'/>
          </Div>
        </Div>
      </Div>
    </footer>
  )
}
