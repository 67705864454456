import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation,Autoplay } from 'swiper';
import { Icon } from '@iconify/react';
import parse from 'html-react-parser';
import Div from '../Div';

export default function FullScreenHorizontalSlider({ data }) {
  return (
    <Div className="cs-swiper_arrow_style_1">
      <Div className="swiper-button image-swiper-button-next">
        Next <Icon icon="bi:arrow-right" />
      </Div>
      <Div className="swiper-button image-swiper-button-prev">
        <Icon icon="bi:arrow-left" /> Prev
      </Div>
      <Swiper

        slidesPerView={1}
        spaceBetween={0}

        pagination={{
          clickable: true,
        }}

        speed={500}
        loop={true}
        modules={[ Pagination, Navigation,Autoplay]}
        className="mySwiper"
        autoplay
        navigation={{
          nextEl: '.image-swiper-button-next',
          prevEl: '.image-swiper-button-prev',
          disabledClass: 'swiper-button-disabled',
        }}
      >
        
        {data.map((item, index) => (
          <SwiperSlide key={index}>
            <Div
              className={`cs-hero cs-style4 cs-bg cs-img-${index} `}
              style={{ backgroundImage: `url(${item.imgUrl})` }}
            >
            
              <Div className="cs-hero_text">
                <h4 className="cs-hero_title">{parse(item.title)}</h4>
              </Div>
            </Div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Div>
  );
}
