import React from 'react'
import './team.scss'
import Div from '../Div';

export default function Team({memberImage, memberName, memberDesignation}) {
  return (
    <Div className="cs-team cs-style1">
        <Div className="cs-member_thumb">
          <img src={memberImage} alt={memberName} />
          <Div className="cs-member_overlay" />
        </Div>
        <Div className="cs-member_info">
          <h2 className="cs-member_name">{memberName}</h2>
          <Div className="cs-member_designation">{memberDesignation}</Div>
        </Div>

      </Div>
  )
}
