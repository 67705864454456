
import Div from '../Div';
import SectionHeading2 from '../SectionHeading/sectionHeading2';
import Spacing from '../Spacing';
import TimelineSlider from '../Slider/TimelineSlider';
export default function Values() {
    return(
        <Div className="cs-shape_wrap_2">
        <Div className="cs-shape_2">
          <Div />
        </Div>
        <Spacing lg="80" md="0" />
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4 values ">
              <SectionHeading2
                title=""
                subtitle="Our Values"
                variant="cs-style16"


              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <TimelineSlider />
            </Div>
          </Div>
        </Div>
      </Div>
      )
}
