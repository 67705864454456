import React from 'react';
import Div from '../Div';
import { Icon } from '@iconify/react';
import Spacing from '../Spacing';
const ContactForm = ({ handleSubmitForm, handleChange, contact, errors }) => {
 
  return (
    <form noValidate className="row" onSubmit={handleSubmitForm}>

    <Div className="col-sm-6">
      <label className="cs-primary_color">First Name<span className='text-danger'>*</span></label>
      <input  type="text" className="cs-form_field mb-2" onChange={handleChange} required  name='firstName' value={contact.firstName} />
      {errors.firstName && <p className='text-danger'>{errors.firstName}</p>}
      <Spacing lg="20" md="20" />
    </Div>
    <Div className="col-sm-6">
      <label className="cs-primary_color">Last Name<span className='text-danger'>*</span></label>
      <input type="text" className="cs-form_field mb-2" onChange={handleChange}  name='lastName' value={contact.lastName} />
      {errors.lastName && <p className='text-danger'>{errors.lastName}</p>}
      <Spacing lg="20" md="20" />
    </Div>
    <Div className="col-sm-6">
      <label className="cs-primary_color">Email<span className='text-danger'>*</span></label>
      <input type="email" className="cs-form_field mb-2" onChange={handleChange}  name='businessEmail' value={contact.businessEmail}/>
      {errors.businessEmail && <p className='text-danger'>{errors.businessEmail}</p>}
      <Spacing lg="20" md="20" />
    </Div>
    <Div className="col-sm-6">
      <label className="cs-primary_color">Mobile<span className='text-danger'>*</span></label>
      <input type="text" className="cs-form_field mb-2" onChange={handleChange} name='firstPhone' value={contact.firstPhone} />
      {errors.firstPhone && <p className='text-danger'>{errors.firstPhone}</p>}
      <Spacing lg="20" md="20" />
    </Div>
    {
      contact.message===""?
      <Div className="col-sm-12">
      <label className="cs-primary_color">Message<span className='text-danger'>*</span></label>
      <textarea
        onChange={handleChange}
        cols="30"
        rows="7"
        className="cs-form_field mb-2"
        name='message'
        required
      >
      {contact.message}
      </textarea>
      {errors.message && <p className='text-danger'>{errors.message}</p>}

      <Spacing lg="25" md="25" />
    </Div>
    :""
    }
 
    <Div className="col-sm-12">
      <button className="cs-btn cs-style1">
        <span>Send Message</span>
        <Icon icon="bi:arrow-right" />
      </button>

    </Div>
  </form>
  );
}

export default ContactForm;