import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../helper'
import Cta from '../components/Cta'
import PageHeading from '../components/PageHeading'
import Div from '../components/Div'
import Spacing from '../components/Spacing'
import { useState } from 'react'
import blogApi from '../apiServices/newsApi'
import { Helmet } from 'react-helmet';
import AnimatedCircularProgress from '../components/CircularProgress';
import NewsCard from '../components/NewsCard'
import ScrollToTop from "react-scroll-to-top";
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
// export default function NewsDetailsPage() {
//   const {blogId}= useParams()
//   const [blog, setBlog] = useState([]);
//   const [progress, setProgress] = useState(20);

//   useEffect(() => {
//    blogApi.getOneNews(blogId)
//       .then(response => {
//         setBlog(response.data.blogitem);
//         console.log(response.data)
//         console.log(blog)
//       })
//       .catch(error => {
//         console.error(error);
//       });
//   }, []);
//   const displayItems=blog.map((item, index)=> {
//     return(
//     <Div key={index} className="col-12 col-md-6 col col-lg-3 col-sm-12" >
//       <Div className="container">
//       <NewsCard key={index}

//         imageCover={item.imageCover}

//       />
//     {blog.length>index+1 && <Spacing lg='95' md='60'/>}
//     </Div>
//   </Div>

// );
//     })
//   return (
//     <>
//     <Helmet>
//      <title>{pageTitle('Event Pictures')}</title>
//      <meta property="og:title" content="Event Pictures" />

//     </Helmet>
//       <PageHeading
//         title='Event Pictures'
//         bgSrc='/images/service_hero_bg.jpeg'
//         pageLinkText="Event Pictures"
//       />
//       <Div className="container mt-5">


//       <Div className='col-12 '>
//               {
//                 blog.length>0?
//                 <Div className='row'>

//                 {displayItems}
//               </Div>

//                 :
//                 <Div className='container' style={{ width: 200, height: 200 }}>
//                 <AnimatedCircularProgress percentage={progress} />
//                 </Div>
//               }

//             </Div>

//       </Div>
//       <Spacing lg='130' md='80'/>
//       <Div className="container">
//       <Cta
//           title="Let's discuss making  <br />something <i>cool</i>"
//           btnText="Contact Us"
//           btnLink="/contact"
//           bgSrc="/images/cta_bg_3.jpeg"
//         />
//       </Div>
//       <ScrollToTop smooth  className='scrolltotop' color='#b7346c' width="20" height="20" viewBox="0 0 20 20"  xmlns="http://www.w3.org/2000/svg" svgPath= "M0 10L1.7625 11.7625L8.75 4.7875V20H11.25V4.7875L18.225 11.775L20 10L10 0L0 10Z"/>
//     </>
//   )
// }

const queryClient = new QueryClient();

const fetchBlog = async ({ queryKey }) => {
  const [_key, blogId] = queryKey;
  const response = await blogApi.getOneNews(blogId);
  return response.data.blogitem;
};

function NewsDetailsComponent() {
  const { blogId } = useParams();
  const [progress, setProgress] = useState(20);

  const { data: blog = [], error, isLoading } = useQuery({
    queryKey: ['blog', blogId],
    queryFn: fetchBlog,
  });

  const displayItems = blog.map((item, index) => (
    <Div key={index} className="col-12 col-md-6 col col-lg-3 col-sm-12">
      <Div className="container">
        <NewsCard key={index} imageCover={item.imageCover} />
        {blog.length > index + 1 && <Spacing lg='95' md='60' />}
      </Div>
    </Div>
  ));

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 40));
    }, 1000);
    return () => clearInterval(interval);
  }, []);


  return (
    <>
      <Helmet>
        <title>{pageTitle('Event Pictures')}</title>
        <meta property="og:title" content="Event Pictures" />
      </Helmet>
      <PageHeading
        title='Event Pictures'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText="Event Pictures"
      />
      <Div className="container mt-5">
        <Div className='col-12'>
          {blog.length > 0 ? (
            <Div className='row'>
              {displayItems}
            </Div>
          ) : (
            <Div className='container' style={{ width: 200, height: 200 }}>
              <AnimatedCircularProgress percentage={progress} />
            </Div>
          )}
        </Div>
      </Div>
      <Spacing lg='130' md='80' />
      <Div className="container">
        <Cta
          title="Let's discuss making  <br />something <i>cool</i>"
          btnText="Contact Us"
          btnLink="/contact"
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      <ScrollToTop
        smooth
        className='scrolltotop'
        color='#b7346c'
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        svgPath="M0 10L1.7625 11.7625L8.75 4.7875V20H11.25V4.7875L18.225 11.775L20 10L10 0L0 10Z"
      />
    </>
  );
}

export default function NewsDetailsPage() {
  return (
    <QueryClientProvider client={queryClient}>
      <NewsDetailsComponent />
    </QueryClientProvider>
  );
}
